
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import CompanyForm from '../components/CompanyForm.vue';
import BaseFormElement from '../components/BaseFormElement.vue';

import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';

import { Company } from '../api/companies/company.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '../shared/types/form-element.class';
import { Watch } from 'vue-property-decorator';
import { Invite } from '../api/users/invite.model';
import { Role } from '../api/auth/role.enum';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const companiesModule = namespace('companies');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseTable, BaseHeader, CompanyForm, BaseFormElement },
})
export default class Companies extends Vue {
  $refs!: Vue['$refs'] & {
    detailModal: BModal;
    createModal: BModal;
  };

  isLoading = false;

  tabIndex = 0;
  columns: Column[] = [
    new Column('name', 'Name'),
    new Column('email', 'Email'),
    new Column('description', 'Description'),
  ];

  inviteAdmin = {
    invite: false,
    email: '',
  };
  adminEmailElement = new FormElement('email', 'Admin e-mail');
  inviteAdminCheckboxElement = new FormElement(
    'invite',
    'Invite admin when creating the company?',
    new FormElementOptions(),
    '',
    FormElementType.CHECKBOX,
  );

  companyForDetail: Company = new Company();
  newCompany: Company = new Company();

  filter: Filter = new Filter();

  @companiesModule.Getter('all')
  companies!: Company[];

  @companiesModule.Getter('pagination')
  pagination!: PaginateResult<Company>;

  @companiesModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Company[]>;

  @companiesModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  @companiesModule.Action('create')
  create!: (company: Company) => Promise<Company>;

  @companiesModule.Action('update')
  update!: (company: Company) => Promise<Company>;

  @usersModule.Action('inviteUsers')
  inviteUsers!: (invite: Invite) => Promise<void>;

  @Watch('inviteAdmin.invite')
  onInviteChanged(newValue: boolean) {
    if (newValue) {
      this.inviteAdmin.email = this.newCompany.email;
    }
  }

  async doCreate(company: Company) {
    const createdCompany = await this.create(company);
    this.newCompany = new Company();
    if (!this.inviteAdmin.invite) {
      return;
    }
    const invite = new Invite(
      [this.inviteAdmin.email],
      Role.CompanyAdmin,
      undefined,
      createdCompany._id,
    );
    await this.inviteUsers(invite);
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  openCreateModal() {
    this.$refs.createModal.show();
  }

  openDetail(company: Company) {
    this.companyForDetail = company;
    this.$refs.detailModal.show();
  }

  async doDelete(company: Company) {
    const value = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${company.name}?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );

    if (!value) {
      return;
    }
    await this.delete(company._id);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
