import { BaseModel } from '@/shared/types/base.model';
import Address from '@/shared/types/address.class';

export class Company extends BaseModel {
  constructor(
    public name?: string,
    public email?: string,
    public vat?: string,
    public description?: string,
    public address?: Address,
  ) {
    super();
  }
}
