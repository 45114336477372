





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { Company } from '../api/companies/company.model';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class CompanyForm extends Vue {
  @Prop()
  company!: Company;

  formElements: FormElement[][] = [
    [
      new FormElement('name', 'Name', new FormElementOptions(), 'required'),
      new FormElement('email', 'Email', new FormElementOptions(), 'required'),
    ],
    [
      new FormElement(
        'description',
        'Description',
        new FormElementOptions(),
        '',
        FormElementType.TEXTAREA,
      ),
    ],
    [new FormElement('vat', 'VAT', new FormElementOptions())],
    [
      new FormElement(
        'address.streetAndNumber',
        'Street and number',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'address.zipCode',
        'Zip Code',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'address.city',
        'City',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'address.countryCode',
        'Country',
        new FormElementOptions(),
        'required',
      ),
    ],
  ];
}
